import i18n from "i18next";
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import resources from './translations';


i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    detection: ['navigator'],
    resources,
    backend: {
      loadPath: "/translations/{{lng}}/{{ns}}.json",
    },
    fallbackLng: 'es',
    interpolation: { escapeValue: false }
  });
export default i18n;